import styled from '../../theme/styled-with-theme';
import { mediaQueries } from '../../styles/media-queries';
import { ParagraphWithLineBreaks } from '../RichText/styles';

export const DisclosureParagraph = styled(ParagraphWithLineBreaks)`
  text-align: center;
  ${({ theme }) => ({ marginTop: theme.spacing.m })};
  ${({ theme }) => ({ fontSize: theme.fontSize['2xs'] })};

  ${mediaQueries.tablet} {
    ${({ theme }) => ({ fontSize: theme.fontSize.s })};
  }
`;

export const ImageWrapper = styled('div')`
  img {
    border-radius: ${({ theme }) => theme.borderRadius.innerBlock};
  }
`;
