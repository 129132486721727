import React, { useMemo } from 'react';
import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import { Asset } from 'contentful';
import cx from 'classnames';

import { IFinalCalloutFields } from '../../../types/contentful';

import RichText from '../RichText';
import { EmbeddedInlineEntryNode } from '../RichText/Nodes';
import CtaWidget from '../CtaWidget';

import {
  ctaWidgetEnum,
  DEFAULT_LABEL,
  DEFAULT_PLACEHOLDER
} from '../CtaWidget/shared';

import { InlineAnchor } from '../RichText/styles';
import { DisclosureParagraph, ImageWrapper } from './styles';
import { getImageData } from 'src/utils/contentful';
import GlImage from '../Image';
import ImageLink from '../ImageLink';
import { ScrollReveal } from '../ScrollReveal';

const DEFAULT_HEADLINE = 'Try today. Our treat.';
const DEFAULT_SUBHEADLINE =
  'After your one month trial, plans start at just $4.99/month for the whole family. Includes up to five kids.';

interface Props extends IFinalCalloutFields {
  sysId?: string;
}

export function FinalCallout({
  headerText = DEFAULT_HEADLINE,
  subheaderText = DEFAULT_SUBHEADLINE,
  useLightText = false,
  backgroundColor,
  registrationEntrypoint,
  privacyPolicy,
  calloutImage,
  imageOrientation,
  leftAlignText,
  calloutImageLink,
  sysId
}: Props) {
  const RICH_TEXT_OVERRIDE: RenderNode = useMemo(
    () => ({
      [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => (
        <DisclosureParagraph colour={useLightText ? 'white' : 'defaultText'}>
          {children}
        </DisclosureParagraph>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <InlineAnchor
          href={node.data.uri}
          colour={useLightText ? 'white' : 'defaultText'}
        >
          {children}
        </InlineAnchor>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => (
        <EmbeddedInlineEntryNode
          node={node}
          colour={useLightText ? 'white' : 'defaultText'}
          fontWeight="p"
        >
          {children}
        </EmbeddedInlineEntryNode>
      )
    }),
    [useLightText]
  );

  return (
    <ScrollReveal>
      <section
        className="mb-[120px] flex w-full justify-center"
        data-blockid="final-cta"
        id={sysId}
        data-testid="final-callout"
      >
        <div
          className={cx(
            'mx-5 flex max-w-screen-max grow basis-full flex-col items-center justify-center rounded-[32px] px-5 py-16 tablet:mx-10 tablet:flex-row tablet:rounded-[48px] tablet:px-12 desktop:mx-16',
            { 'flex-col-reverse tablet:flex-row-reverse': !imageOrientation }
          )}
          data-testid="final-callout-layout"
          style={{
            backgroundColor: backgroundColor ? `#${backgroundColor}` : '#99E4D7'
          }}
        >
          {(calloutImageLink || calloutImage) && (
            <div
              className={cx(
                'w-full desktop:w-6/12',
                imageOrientation
                  ? 'mb-8 tablet:mb-0 tablet:mr-8'
                  : 'mb-0 mt-8 tablet:ml-8 tablet:mr-0 tablet:mt-0'
              )}
            >
              {calloutImageLink ? (
                <ImageLink {...calloutImageLink.fields}>
                  {(image) => {
                    return (
                      <ImageWrapper>
                        <GlImage
                          {...getImageData(image)}
                          loading="eager"
                          heroImage={false}
                        />
                      </ImageWrapper>
                    );
                  }}
                </ImageLink>
              ) : (
                <ImageWrapper>
                  <GlImage
                    {...getImageData(calloutImage as Asset)}
                    loading="eager"
                    heroImage={false}
                  />
                </ImageWrapper>
              )}
            </div>
          )}
          <div className="flex flex-col tablet:max-w-screen-sm desktop:max-w-lg max:w-5/12">
            <h2
              className={cx(
                'text-center text-4xl font-medium leading-9 desktop:text-5xl desktop:leading-tight',
                useLightText && 'text-white',
                leftAlignText && 'tablet:text-left'
              )}
              style={{ letterSpacing: '.5px' }}
            >
              {headerText}
            </h2>
            <p
              className={cx(
                'mt-4 text-center text-base leading-6',
                useLightText && 'text-white',
                leftAlignText && 'tablet:text-left'
              )}
              role="paragraph"
            >
              {subheaderText}
            </p>
            <div
              className={cx(
                'mt-4 flex flex-col items-center justify-center justify-self-center',
                'tiny:max-tablet:[&_:is(div,form)]:w-full', // makes the cta to be full width on mobile
                'tablet:[&_:is(div,form)]:w-[312px]', // makes the cta to be 312px wide on tablet and above
                leftAlignText && 'tablet:items-start'
              )}
              data-testid="final-cta"
              data-e2e="final-cta"
            >
              <CtaWidget
                id="final-cta-phone-number"
                module="Final Callout"
                errorPosition="bottom"
                designType={ctaWidgetEnum.MOBILE_PHONE_NUMBER}
                label={DEFAULT_LABEL}
                placeholder={DEFAULT_PLACEHOLDER}
                {...registrationEntrypoint?.fields}
                sysId={registrationEntrypoint?.sys?.contentType?.sys?.id}
              />
            </div>
            {privacyPolicy && (
              <div className="w-full self-center tablet:w-[312px]">
                <RichText
                  colour="footerText"
                  data={privacyPolicy}
                  overrideOptions={RICH_TEXT_OVERRIDE}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </ScrollReveal>
  );
}
